
import { defineComponent, ref, onMounted, reactive, computed, getCurrentInstance } from 'vue'
import moment from 'moment'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { AgGridVue, } from "ag-grid-vue3"
import { GridReadyEvent, GridApi } from "ag-grid-community";
import VPagination from "@hennge/vue3-pagination"
import { Department, DepartmentInGrid } from '@/model'
import { AxiosResponse } from 'axios'
import "@/sass/pagination.scss"

export default defineComponent({
  name: 'department',
  components: {
    AgGridVue,
    VPagination,    
  },
  setup() {
    const router = useRouter()
    const departments = reactive([] as Array<DepartmentInGrid>)
    const keyword = ref('')
    let gridApi : GridApi
   
    const columnColumnDefs = [
        { headerName: "序号", field: "serialNo", width:120,checkboxSelection: true, headerCheckboxSelection: true },
        { headerName: "部门名称", field: "name", width:350, resizable:true },
        { headerName: "上级部门", field: "parentDepartment.name", width:350, resizable:true },
        { headerName: "简述", field: "description", flex: 1, minWidth: 300, resizable:true },
    ]
    let recordCount = ref(1)
    const currentPage = ref(1)
    const pageSize = ref(30)
    const pageCount = computed(() => Math.ceil(recordCount.value / pageSize.value))
    function currengPageChanged(page: number)
    {
      selectedItems.value.splice(0, selectedItems.value.length)
      currentPage.value = page
      searchRecords()
    }
    let selectedItems = ref([] as Array<DepartmentInGrid>)

    function onSelectionChanged() {
      selectedItems.value.splice(0, selectedItems.value.length)
      Array.prototype.push.apply(selectedItems.value, gridApi.getSelectedRows() as Array<DepartmentInGrid>)
    }

    function onGridReady(params: GridReadyEvent) {
      gridApi = params.api
      searchRecords()
    }

     function redirect2ProjectInfo() {
      router.push(`/departments/${selectedItems.value[0].id}/corporate`)
    }

    function searchRecords() {
      let $filter = ''
      if (keyword.value != '') {
        $filter = `&$filter=contains(name,'${keyword.value}')`
      }
      gridApi.showLoadingOverlay();
      axiosService.get(`/odata/OwnedDepartments/$count?${$filter}`).then((res : AxiosResponse<number>) => {
        recordCount.value = res.data
      })

      axiosService.get(`/odata/OwnedDepartments?&$orderby=departmentLevel,name&$expand=parentDepartment($select=Name)${$filter}&$top=${pageSize.value}&$skip=${(currentPage.value - 1) * pageSize.value}`).then(res => {
        departments.splice(0, departments.length)
        Array.prototype.push.apply(
          departments,
          res.data.value.map((c: Department, index: number) => {
            return {
              id: c.id,
              serialNo: ((currentPage.value - 1) * pageSize.value ) + (index + 1),
              name: c.name,
              description: c.description,
              telephoneNo: c.telephoneNo,
              fax: c.fax,
              parentDepartmentId: c.parentDepartmentId,
              parentDepartment: c.parentDepartment || {},
              departmentLevel: c.departmentLevel,
              createdOn: c.createdOn
            }
          })
        )
        gridApi.setRowData(departments)
        gridApi.hideOverlay()
      })
    }


    function selectionChange(evt: any)
    {
      selectedItems.value = evt.currentSelectedItems
    }



    return {
      name: 'corporate',
      keyword,
      columnColumnDefs,
      searchRecords,
      selectedItems,
      selectionChange,
      currentPage,
      onGridReady,
      onSelectionChanged,
      pageCount,
      pageSize,
      currengPageChanged,
      redirect2ProjectInfo
    }
  }
})
